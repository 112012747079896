import React from 'react'
import PageTitle from '../../components/Breadcrumb/PageTitle'
import Card from 'react-bootstrap/Card';
import { useState } from 'react';
import { Tab, Tabs, Button, Table, Form } from 'react-bootstrap';
import { IoIosEye } from 'react-icons/io';
import './allMenus.css'
import { FaPlus, FaCog } from 'react-icons/fa';
import { Modal } from 'react-bootstrap';
import Pagination from '../../components/Pagination/Pagination';
import CustomTable from '../../components/Tables/SimpleCustomTable/SimpleCustomTable';
import TableSearchBar from '../../components/SearchBar/TableSearch';
import { API } from "../../../src/api/api";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFooditems, setItemTag, setLocation, setMenu, setOfferDuration, setTargetaudiance } from '../../redux/actions/dataActions';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { convertToBase64 } from '../../utils/Appconstants';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Multiselect from 'multiselect-react-dropdown';

import { Calendar, DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';

// import { Viewer } from '@react-pdf-viewer/core'
// import '@react-pdf-viewer/core/lib/styles/index.css'
// import '@react-pdf-viewer/default-layout/lib/styles/index.css'
// import { Worker } from '@react-pdf-viewer/core'
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'

// import { PDFReader } from 'reactjs-pdf-reader'

const FormData = () => {
  // const defaultLayoutPluginInstance = defaultLayoutPlugin()
  const dispatch = useDispatch();
  const offer_duration = useSelector(state => state.data.offer_duration);
  const target_audiance = useSelector(state => state.data.target_audiance);
  
  const [initialData, setInitialData] = useState({
    "id":"",
    "feedboack_opt":"",
    "first_name":"",
    "last_name":"",
    "phone_number":"",
    "resume":"",
    "resume_path":"",
    "cover_letter":"",
    "cover_letter_path":"",
    "fsc_certificate":"",
    "fsc_certificate_path":"",
    "email_id":"",
    "message":"",
    "tag_c_timestamp":"",
  });
  const [onviewclicked, setOnviewclicked] = useState(false)
  const [showModal, setShowModal] = useState(false);
  const [offerdata, setOfferdata] = useState([])
  const [offerduration, setOfferduration] = useState([])
  

  useEffect(() => {
    GetFormData()
  }, []);

  const GetFormData = async () => {
    console.log('GetFooditemsData===>')
    API.getInstance().base.get("/api/submit-form")
      .then((res) => {
        // dispatch(setFooditems(res.data.result.data));
        setOfferdata(res.data.result.data)
      })
      .catch((error) => {
      })
      .finally(() => {

      });
  }

 

  const handleOpenCreateMenu = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setOnviewclicked(false);
    GetFormData()
  };

  // Pagination state ====
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = offerdata?.slice(indexOfFirstItem, indexOfLastItem);
  // Change page
  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };
  const goToPage = (page) => {
    setCurrentPage(page);
  };
  const totalPages = Math.ceil(offerdata?.length / itemsPerPage);
  const displayPages = Math.min(totalPages, 3); // Ensure we display maximum 3 pages





  // Search code 
  const [searchValue, setSearchValue] = useState('');
  const handleSearch = (value) => {
    setSearchValue(value);
  };
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isValidForm, setIsValidForm] = useState(false); // State variable to store isValid value


  const formatDate = (dateObj) => {
    if (!dateObj || typeof dateObj !== 'object') return null;
    
    const { startDate, endDate } = dateObj;
  
    const formatDatePart = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
  
      // Ensure leading zeros for day and month if needed
      const formattedDay = day < 10 ? `0${day}` : day;
      const formattedMonth = month < 10 ? `0${month}` : month;
  
      return `${formattedDay}/${formattedMonth}/${year}`;
    };
  
    const formattedStartDate = formatDatePart(startDate);
    const formattedEndDate = formatDatePart(endDate);
  
    return {
      startDate: formattedStartDate,
      endDate: formattedEndDate
    };
  };
  


  

  const fetchInitialData = async (id) => {
    console.log('id===11', id)
    try {
      console.log('id===', id)
      const response = await API.getInstance().menu.get(`/api/submit-form?id=${id}`); // Adjust the API endpoint as needed
      console.log(response.data.result.data[0], 'response.data====>11')
      // setInitialData(response.data.result.data)
      setInitialData(response.data.result.data[0])
    
    } catch (error) {
      console.error('Error fetching initial data:', error);
    }
  };
  
  const handleOnView = async (row) => {
    await fetchInitialData(row[0]);
    setOnviewclicked(true)
    setShowModal(true);
  }



  // date range
  const [state, setState] = useState({
    selection1: {
      startDate: null,
      endDate: null,
      key: 'selection1'
    }
  });
  console.log(initialData,'initialData==111====>')
  const handleClick = (cover_link) => {
    window.open(cover_link, '_blank');
  };
  const handleClickResume = (cover_link) => {
    window.open(cover_link, '_blank');
  };
  return (


    <main id="main" className='main'>
      <Card className="overflow-auto card-custom">
        <Card.Body>
          <PageTitle page="Form Data" />
          <div className='Tab-parent'>
            <div className='Tab-parent'>

              <div>
                <div className="container">


                  <ul className="nav nav-pills mb-3 " id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button className="nav-link  fw-semibold position-relative active" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Form Data</button>
                    </li>

                  </ul>

                  <TableSearchBar placeholder="Search menu" onSearch={handleSearch} />


                  <div className="tab-content  rounded-3 mt-4" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                    <CustomTable
                    headers={['Id', 'Option',  'First Name','Email', 'Message','FSC', 'Action']}
                    data={offerdata?.map(data => [
                        data.id,
                        data.feedboack_opt,
                        data.first_name,
                        data.email_id,
                        data.message,
                        data.fsc_opt === '1'  ? 'FSC Submited': data.fsc_opt ? 'I will get the FSC within 30 days' : 'Not Uploaded' // Add condition here
                    ])}
                    actions={[{ icon: <IoIosEye style={{ color: 'white', fontSize: '20px' }} />, onClick: handleOnView }]}
                />
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        displayPages={displayPages}
                        goToPage={goToPage}
                        prevPage={prevPage}
                        nextPage={nextPage}
                      />
                    </div>
                  </div>
                </div>

              </div>
              <div className='Tab-right d-flex align-items-center'>
                {/* <Button
                  variant="primary"
                  className="me-md-2 me-0 mb-2 mb-md-0 btn-lg"
                  style={{
                    height: "40px",
                    backgroundColor: 'rgb(180,49,45)',
                    fontSize: "12px",
                    border: "none",
                    fontFamily: "Popins"
                  }}
                  tabIndex={0} // Add tabIndex attribute and set it to 0
                  onClick={handleOpenCreateMenu}
                >
                  <FaPlus /> <span style={{ marginLeft: '5px' }}>Create New Menu</span>
                </Button> */}


              </div>
            </div>
            {/* dashboard + Create New Menu button */}
            <div className='Tab-right d-flex align-items-center'>
              {/* <Button
                variant="primary"
                className="me-md-2 me-0 mb-2 mb-md-0 btn-lg"
                style={{
                  height: "40px",
                  backgroundColor: 'rgb(180,49,45)',
                  fontSize: "12px",
                  border: "none",
                  fontFamily: "Popins"
                }}
                tabIndex={0}
                onClick={handleOpenCreateMenu} // Open modal on button click
              >
                <FaPlus /> <span style={{ marginLeft: '5px' }}>Create New Menu</span>
              </Button> */}
            </div>
          </div>
        </Card.Body>
      </Card>

      {/* Modal */}
      <Modal show={showModal} onHide={handleCloseModal} centered backdrop="static">
        <Modal.Header closeButton style={{ borderLeftStyle: '10px', borderBottom: "none", display: "flex", justifyContent: "space-between", backgroundColor: `var(--card-dark-shade)` }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Modal.Title style={{ color: 'white' }}>{'Form Data'}</Modal.Title>
          </div>
        </Modal.Header>

        <Modal.Body style={{ backgroundColor: `var(--card-dark-shade)` }}>
          <Formik
            enableReinitialize
            initialValues={initialData}
          >
            {({ isValid, values, handleChange, handleSubmit, setFieldValue, errors, touched, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <div className="input__wrapper" style={{ width: "100%" }}>
                    <Field readOnly
                      type="text"
                      placeholder="First Name"
                      className="input__field"
                      name="feedboack_opt"
                      value={values.feedboack_opt}
                     // onChange={handleChange}
                      style={{ width: "100%" }}
                    />
                    <label htmlFor="promo_code" className="input__label">Feedback Option<span style={{ color: 'red' }}> *</span></label>
                  </div>
                  <ErrorMessage name="promo_code" component="div" className="error-message" />
                </Form.Group>
                
                <Form.Group className="mb-3">
                  <div className="input__wrapper" style={{ width: "100%" }}>
                    <Field readOnly
                      type="text"
                      placeholder="First Name"
                      className="input__field"
                      name="first_name"
                      value={values.first_name}
                     // onChange={handleChange}
                      style={{ width: "100%" }}
                    />
                    <label htmlFor="promo_code" className="input__label">First Name<span style={{ color: 'red' }}> *</span></label>
                  </div>
                  <ErrorMessage name="promo_code" component="div" className="error-message" />
                </Form.Group>

                <Form.Group className="mb-3">
                  <div className="input__wrapper" style={{ width: "100%" }}>
                    <Field readOnly
                      type="text"
                      placeholder="Last Name"
                      className="input__field"
                      name="first_name"
                      value={values.last_name}
                     // onChange={handleChange}
                      style={{ width: "100%" }}
                    />
                    <label htmlFor="promo_code" className="input__label">Last Name<span style={{ color: 'red' }}> *</span></label>
                  </div>
                  <ErrorMessage name="promo_code" component="div" className="error-message" />
                </Form.Group>

                <Form.Group className="mb-3">
                  <div className="input__wrapper" style={{ width: "100%" }}>
                    <Field readOnly
                      type="text"
                      placeholder="Phone Number"
                      className="input__field"
                      name="phone_number"
                      value={values.phone_number}
                     // onChange={handleChange}
                      style={{ width: "100%" }}
                    />
                    <label htmlFor="promo_code" className="input__label">Phone Number<span style={{ color: 'red' }}> *</span></label>
                  </div>
                  <ErrorMessage name="promo_code" component="div" className="error-message" />
                </Form.Group>

                <Form.Group className="mb-3">
                  <div className="input__wrapper" style={{ width: "100%" }}>
                    <Field readOnly
                      type="text"
                      placeholder="Phone Number"
                      className="input__field"
                      name="email_id"
                      value={values.email_id}
                     // onChange={handleChange}
                      style={{ width: "100%" }}
                    />
                    <label htmlFor="promo_code" className="input__label">Email Id<span style={{ color: 'red' }}> *</span></label>
                  </div>
                  <ErrorMessage name="promo_code" component="div" className="error-message" />
                </Form.Group>

                <Form.Group className="mb-3">
                  <div className="input__wrapper" style={{ width: "100%" }}>
                    <Field readOnly
                      as="textarea"
                      placeholder="Phone Number"
                      className="input__field"
                      name="message"
                      value={values.message}
                     // onChange={handleChange}
                      style={{ width: "100%" ,height:'130px'}}
                    />
                    <label htmlFor="promo_code" className="input__label">Message<span style={{ color: 'red' }}> *</span></label>
                  </div>
                  <ErrorMessage name="promo_code" component="div" className="error-message" />
                </Form.Group>

                <Form.Group className="mb-3">
                  <div className="input__wrapper" style={{ width: "100%" }}>
                    <Field readOnly
                      type="text"
                      placeholder="Phone Number"
                      className="input__field"
                      name="tag_c_timestamp"
                      value={values.tag_c_timestamp}
                     // onChange={handleChange}
                      style={{ width: "100%" }}
                    />
                    <label htmlFor="promo_code" className="input__label">Sent Date Time<span style={{ color: 'red' }}> *</span></label>
                  </div>
                  <ErrorMessage name="promo_code" component="div" className="error-message" />
                </Form.Group>

                <Form.Group className="mb-3">
                  <div className="input__wrapper" style={{ width: "100%" }}>
                    <Field readOnly
                      type="text"
                      placeholder="Phone Number"
                      className="input__field"
                      name="fsc_opt"
                      value={values.fsc_opt === '1'  ? 'FSC Submited': values.fsc_opt ? 'I will get the FSC within 30 days' : 'Not Uploaded' }
                     // onChange={handleChange}
                      style={{ width: "100%" }}
                    />
                    <label htmlFor="promo_code" className="input__label">Food Safety certificate<span style={{ color: 'red' }}> *</span></label>
                  </div>
                  <ErrorMessage name="promo_code" component="div" className="error-message" />
                </Form.Group>
                 
                {values.cover_letter_path && <Button
                  variant="primary"
                  className="me-md-3 me-0 mb-3 mb-md-0 btn-lg  align-items-center"
                  style={{
                    height: "40px",
                    backgroundColor: 'rgb(180,49,45)',
                    fontSize: "12px",
                    border: "none",
                    marginTop:'20px'
                  }}
                  onClick={() => handleClick(values.cover_letter_path)}
                >
                  {/* <FaPlus style={{ marginRight: '4px' }} /> */}
                  COVER LETTER
                </Button>}

                {values.resume_path && <Button
                  variant="primary"
                  className="me-md-3 me-0 mb-3 mb-md-0 btn-lg align-items-center"
                  style={{
                    height: "40px",
                    backgroundColor: 'rgb(180,49,45)',
                    fontSize: "12px",
                    border: "none",
                    marginTop:'20px'
                  }}
                  onClick={() => handleClick(values.resume_path)}
                >
                  {/* <FaPlus style={{ marginRight: '4px' }} /> */}
                  RESUME LETTER
                </Button>}
                {values.fsc_certificate_path && values.fsc_opt === '1' && <Button
                  variant="primary"
                  className="me-md-3 me-0 mb-3 mb-md-0 btn-lg align-items-center"
                  style={{
                    height: "40px",
                    backgroundColor: 'rgb(180,49,45)',
                    fontSize: "12px",
                    border: "none",
                    marginTop:'20px'
                  }}
                  onClick={() => handleClick(values.fsc_certificate_path)}
                >
                  {/* <FaPlus style={{ marginRight: '4px' }} /> */}
                  FOOD SAFETY CETIFICATE
                </Button>}

              {/* <PDFReader url={"http://127.0.0.1:8000/media/c30e0aa1e9374bfa9f4bb1ec63016e86.pdf"} /> */}
            
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </main>
  )
}

export default FormData
