import React from 'react';
import { HashRouter as Router, Routes, Route, Navigate, Outlet } from 'react-router-dom'; // Import HashRouter
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'remixicon/fonts/remixicon.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './App.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

// Import your components and screens
import Main from './screens/Dashboard/Main';
import EmailMessaging from './screens/Marketing/EmailMessaging';
import OverView from './screens/Marketing/OverView';
import SideBar from './components/Sidebar/SideBar';
import TextMessages from './screens/Marketing/TextMessages';
import AllMenus from './screens/Menu/AllMenus';
import Items from './screens/Menu/Items';
import ItemsTag from './screens/Menu/ItemsTags';
import Gallery from './screens/Gallery/Gallery';
import Settings from './screens/Settings/Settings';
import MyProfile from './screens/Settings/MyProfile';
import BillingPayment from './screens/Settings/BillingPayment';
import TeamManagement from './screens/Settings/TeamManagement';
import Integration from './screens/Settings/Integration';
import General from './screens/Settings/General';
import LocationAndHours from './screens/Settings/LocationAndHours';
import RestaurentTeamManagement from './screens/Settings/RestaurentTeamManagement';
import Product from './screens/Product/Product';
import AllOrders from './screens/Orders/AllOrders';
import AddLocationButton from './screens/Settings/AddLocationButton';
import OrderSettings from './screens/Orders/OrderSettings';
import SignIn from './screens/Signin/signin';
import Report from './screens/Orders/Report';
import Offers from './screens/Menu/Offers';
import {Helmet} from "react-helmet";
import FormData from './screens/Menu/FormData';
// Sidebar layout component
const SidebarLayout = () => (
  <>
    <SideBar />
    <Outlet />
  </>
);

// Main App component
function App() {
  // const isLoggedIn = useSelector(state => state.login_data);
  
  return (
    <Router> {/* Ensure that Router wraps the entire application */}
      <ToastContainer />
      <Routes>
        <Route path="/dashboard/*" element={<ProtectedDashboard />}>
          <Route index element={<Main />} />
          <Route path="marketing/overview" element={<OverView />} />
          <Route path="marketing/email-message" element={<EmailMessaging />} />
          <Route path="marketing/text-message" element={<TextMessages />} />
          <Route path="menu/all-menu" element={<AllMenus />} />
          <Route path="menu/items" element={<Items />} />
          <Route path="menu/offers" element={<Offers />} />
          <Route path="menu/formdata" element={<FormData />} />
          <Route path="menu/items-tags" element={<ItemsTag />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="settings" element={<Settings />} />
          <Route path="MyProfile" element={<MyProfile />} />
          <Route path="billing-payment" element={<BillingPayment />} />
          <Route path="team-management" element={<TeamManagement />} />
          <Route path="integration" element={<Integration />} />
          <Route path="general" element={<General />} />
          <Route path="locationhours" element={<LocationAndHours />} />
          <Route path="locationhours/add-location-button" element={<AddLocationButton />} />
          <Route path="restau-team-management" element={<RestaurentTeamManagement />} />
          <Route path="product" element={<Product />} />
          <Route path="orders/all-orders" element={<AllOrders />} />
          <Route path="orders/orders-settings" element={<OrderSettings />} />
          <Route path="orders/report" element={<Report />} />
        </Route>
        <Route path="/" element={<SignIn />} />
      </Routes>
    </Router>
  );

  // ProtectedDashboard component to handle protected routes
  function ProtectedDashboard() {
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    if (!isLoggedIn) {
      return <Navigate to="/" />;
    }

    return <SidebarLayout />;
  }
}

export default App;
