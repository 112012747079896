import React from 'react'
import PageTitle from '../../components/Breadcrumb/PageTitle'
import Card from 'react-bootstrap/Card';
import { useState } from 'react';
import { Tab, Tabs, Button, Table, Form } from 'react-bootstrap';
import { IoIosEye } from 'react-icons/io';
import './allMenus.css'
import { FaPlus, FaCog } from 'react-icons/fa';
import { Modal } from 'react-bootstrap';
import Pagination from '../../components/Pagination/Pagination';
import CustomTable from '../../components/Tables/SimpleCustomTable/SimpleCustomTable';
import TableSearchBar from '../../components/SearchBar/TableSearch';
import { API } from "../../../src/api/api";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFooditems, setItemTag, setLocation, setMenu, setOfferDuration, setTargetaudiance } from '../../redux/actions/dataActions';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { convertToBase64 } from '../../utils/Appconstants';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Multiselect from 'multiselect-react-dropdown';

import { Calendar, DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';


const Offers = () => {
  const dispatch = useDispatch();
  const offer_duration = useSelector(state => state.data.offer_duration);
  const target_audiance = useSelector(state => state.data.target_audiance);
  
  const [initialData, setInitialData] = useState({
    id: '',
    offer_heading: '',
    offer_duration: [],
    trigger_date: { // Initialize trigger_date
      startDate: null,
      endDate: null,
      key: 'selection1'
    },
    promo_code: '',
    target_audiance: '',
    promo_code_offer_price: '',
    promo_code_offer_percent: '',
    banner_image: null,
    banner_image_view: null,
    banner_image_path:null,
    select_offer_duration:[],
    select_target_audiance:[]
  });
  const [onviewclicked, setOnviewclicked] = useState(false)
  const [showModal, setShowModal] = useState(false);
  const [offerdata, setOfferdata] = useState([])
  const [offerduration, setOfferduration] = useState([])
  
  const UpdateData = async () => {
    setInitialData(prevState => ({
      ...prevState,
      offer_duration: offer_duration
    }));
    // console.log(initialData['default_tax_rate'], 'default_tax_rate=====22')
  }

  useEffect(() => {
    GetOfferDurationData()
    GetTargetaudianceData()
    GetOfferData()
    UpdateData()
  }, []);

  const GetOfferData = async () => {
    console.log('GetFooditemsData===>')
    API.getInstance().base.get("/api/user-offers")
      .then((res) => {
        // dispatch(setFooditems(res.data.result.data));
        setOfferdata(res.data.result.data)
      })
      .catch((error) => {
      })
      .finally(() => {

      });
  }

  const GetOfferDurationData = async () => {
    API.getInstance().base.get("/api/offers-duration")
      .then((res) => {
        // console.log('GetOfferDurationData===>',res.data.result.data)
        dispatch(setOfferDuration(res.data.result.data));
        setOfferduration(res.data.result.data)
        setInitialData(prevState => ({
          ...prevState,
          offer_duration: res.data.result.data
        }));
      })
      .catch((error) => {
      })
      .finally(() => {

      });
  }
  const GetTargetaudianceData = async () => {
    API.getInstance().base.get("/api/target-user")
      .then((res) => {
        // console.log('GetOfferDurationData===>',res.data.result.data)
        dispatch(setTargetaudiance(res.data.result.data));
       
        setInitialData(prevState => ({
          ...prevState,
          target_audiance: res.data.result.data
        }));
      })
      .catch((error) => {
      })
      .finally(() => {

      });
  }

 

  const handleOpenCreateMenu = () => {
    setShowModal(true);
    setInitialData({
      id: '',
      offer_heading: '',
      offer_duration: [],
      trigger_date: { // Initialize trigger_date
        startDate: null,
        endDate: null,
        key: 'selection1'
      },
      promo_code: '',
      target_audiance: [],
      promo_code_offer_price: '',
      promo_code_offer_percent: '',
      banner_image: null,
      banner_image_view: null,
      banner_image_path:null,
      select_offer_duration:[],
      select_target_audiance:[]
    })
    console.log(offer_duration,'offer_duration===')
    
    setInitialData(prevState => ({
      ...prevState,
      offer_duration: offer_duration
    }));

    setInitialData(prevState => ({
      ...prevState,
      target_audiance: target_audiance
    }));


  };

  const handleCloseModal = () => {
    setShowModal(false);
    setOnviewclicked(false);
    GetOfferData()
  };

  // Pagination state ====
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = offerdata?.slice(indexOfFirstItem, indexOfLastItem);
  // Change page
  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };
  const goToPage = (page) => {
    setCurrentPage(page);
  };
  const totalPages = Math.ceil(offerdata?.length / itemsPerPage);
  const displayPages = Math.min(totalPages, 3); // Ensure we display maximum 3 pages





  // Search code 
  const [searchValue, setSearchValue] = useState('');
  const handleSearch = (value) => {
    setSearchValue(value);
  };
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isValidForm, setIsValidForm] = useState(false); // State variable to store isValid value


  const formatDate = (dateObj) => {
    if (!dateObj || typeof dateObj !== 'object') return null;
    
    const { startDate, endDate } = dateObj;
  
    const formatDatePart = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
  
      // Ensure leading zeros for day and month if needed
      const formattedDay = day < 10 ? `0${day}` : day;
      const formattedMonth = month < 10 ? `0${month}` : month;
  
      return `${formattedDay}/${formattedMonth}/${year}`;
    };
  
    const formattedStartDate = formatDatePart(startDate);
    const formattedEndDate = formatDatePart(endDate);
  
    return {
      startDate: formattedStartDate,
      endDate: formattedEndDate
    };
  };
  const handleSubmit = async (values, { setSubmitting }) => {
    console.log(values,'valuesss===>')
    console.log(values.trigger_date,'valuesss===>')

    // if (values.trigger_date['key']){
    //   const formattedDates = formatDate(values.trigger_date);
    //   console.log(formattedDates,'formattedDates===>')
    //   values.trigger_date = formattedDates;
    // }
    
    try {
      // Check if backgroundImage exists and convert it to base64 if it does
      if (values.banner_image && values.banner_image instanceof Blob) {
        const base64Image = await convertToBase64(values.banner_image);
        // Update the values with the base64-encoded image
        values.banner_image = base64Image;
      } else {
        // If backgroundImage doesn't exist or is not a Blob, remove it from the values object
        delete values.banner_image;
      }
  
      if (onviewclicked == true) {
        API.getInstance().menu.put(`/api/user-offers/${values.id}`, values)
        .then((res) => {
          // GetMenuData();
          toast.success('Offer updated successfully!'); // Display toaster message
          setSubmitting(false); // Disable form submission
          setModalIsOpen(false); // Close the modal
          handleCloseModal();
        })
        .catch((error) => {
          // Handle error
          toast.error('Failed to update offer!'); // Display failure toaster message
          setSubmitting(false); // Disable form submission
          handleCloseModal();
        });
      } else {
        API.getInstance().menu.post("/api/user-offers", values)
        .then((res) => {
          // GetMenuData();
          toast.success('Offer added successfully!'); // Display toaster message
          setSubmitting(false); // Disable form submission
          setModalIsOpen(false); // Close the modal
          handleCloseModal();
        })
        .catch((error) => {
          // Handle error
          toast.error('Failed to add Offer!'); // Display failure toaster message
          setSubmitting(false); // Disable form submission
          handleCloseModal();
        });
      }
      // Disable the menu modal after successfully adding or updating data
      setModalIsOpen(false);
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitting(false); // Disable form submission
    }
  };
  



  const validationSchema = Yup.object().shape({
    offer_heading: Yup.string().required('Offer Name is required'),
    offer_duration: Yup.array().min(1, 'Offer duration is required'),
    // trigger_date: Yup.object().shape({
    //   // Define validation rules for trigger_date object if needed
    // }),
    promo_code: Yup.string().required('Promo code is required'),
    target_audiance: Yup.array().min(1, 'Target audience is required'),
    // promo_code_offer_price: Yup.string().required('Promo code offer price is required'),
    promo_code_offer_percent: Yup.string().required('Promo code offer percent is required'),
    // banner_image: Yup.mixed().required('Banner image is required'),
    // banner_image_view: Yup.mixed().required('Banner image view is required'),
    select_offer_duration: Yup.array().min(1, 'Selected offer duration is required'),
    select_target_audiance: Yup.array().min(1, 'Selected target audience is required'),
  });

  

  const fetchInitialData = async (id) => {
    console.log('id===11', id)
    try {
      console.log('id===', id)
      const response = await API.getInstance().menu.get(`/api/user-offers?id=${id}`); // Adjust the API endpoint as needed
      console.log(response.data.result.data[0], 'response.data====>')
      const data = response.data.result.data[0]
          const offer_data = {
            id: data.id,
            offer_heading: data.offer_heading,
            trigger_date: data.trigger_date,
            promo_code: data.promo_code,
            promo_code_offer_percent: data.promo_code_offer_percent,
            banner_image: null,
            banner_image_view: null,
            banner_image_path:data.banner_image_path,
            select_offer_duration:data.select_offer_duration,
            select_target_audiance:data.select_target_audiance
          }
      console.log(offer_data, 'offer_data====')
      setInitialData(offer_data); // Assuming response.data is an object with menu_title, menu_display_title, disclaimer, and backgroundImage properties

      setInitialData(prevState => ({
        ...prevState,
        offer_duration: offer_duration
      }));

      setInitialData(prevState => ({
        ...prevState,
        target_audiance: target_audiance
      }));
    } catch (error) {
      console.error('Error fetching initial data:', error);
    }
  };
  
  const handleOnView = async (row) => {
    await fetchInitialData(row[0]);
    setOnviewclicked(true)
    setShowModal(true);
  }



  // date range
  const [state, setState] = useState({
    selection1: {
      startDate: null,
      endDate: null,
      key: 'selection1'
    }
  });
  // console.log(state,'state====>')

  return (


    <main id="main" className='main'>
      <Card className="overflow-auto card-custom">
        <Card.Body>
          <PageTitle page="Offers" />
          <div className='Tab-parent'>
            <div className='Tab-parent'>

              <div>
                <div className="container">


                  <ul className="nav nav-pills mb-3 " id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button className="nav-link  fw-semibold position-relative active" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Offers</button>
                    </li>

                  </ul>

                  <TableSearchBar placeholder="Search menu" onSearch={handleSearch} />


                  <div className="tab-content  rounded-3 mt-4" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                      <CustomTable
                        headers={['Id', 'Name','Banner',  'Promo code','Target', 'Duration', 'Action']}
                        data={offerdata?.map(data => [data.id, data.offer_heading,data.banner_image_path,  data.promo_code,data.select_target_audiance[0]['name'], data.select_offer_duration[0]['name']])}
                        actions={[{ icon: <IoIosEye style={{ color: 'white', fontSize: '20px' }} />, onClick: handleOnView }]}
                      />
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        displayPages={displayPages}
                        goToPage={goToPage}
                        prevPage={prevPage}
                        nextPage={nextPage}
                      />
                    </div>
                  </div>
                </div>

              </div>
              <div className='Tab-right d-flex align-items-center'>
                <Button
                  variant="primary"
                  className="me-md-2 me-0 mb-2 mb-md-0 btn-lg"
                  style={{
                    height: "40px",
                    backgroundColor: 'rgb(180,49,45)',
                    fontSize: "12px",
                    border: "none",
                    fontFamily: "Popins"
                  }}
                  tabIndex={0} // Add tabIndex attribute and set it to 0
                  onClick={handleOpenCreateMenu}
                >
                  <FaPlus /> <span style={{ marginLeft: '5px' }}>Create New Menu</span>
                </Button>


              </div>
            </div>
            {/* dashboard + Create New Menu button */}
            <div className='Tab-right d-flex align-items-center'>
              <Button
                variant="primary"
                className="me-md-2 me-0 mb-2 mb-md-0 btn-lg"
                style={{
                  height: "40px",
                  backgroundColor: 'rgb(180,49,45)',
                  fontSize: "12px",
                  border: "none",
                  fontFamily: "Popins"
                }}
                tabIndex={0}
                onClick={handleOpenCreateMenu} // Open modal on button click
              >
                <FaPlus /> <span style={{ marginLeft: '5px' }}>Create New Menu</span>
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>

      {/* Modal */}
      <Modal show={showModal} onHide={handleCloseModal} centered backdrop="static">
        <Modal.Header closeButton style={{ borderLeftStyle: '10px', borderBottom: "none", display: "flex", justifyContent: "space-between", backgroundColor: `var(--card-dark-shade)` }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Modal.Title style={{ color: 'white' }}>{onviewclicked ? 'Update Offer' : 'New Offer'}</Modal.Title>
          </div>
        </Modal.Header>

        <Modal.Body style={{ backgroundColor: `var(--card-dark-shade)` }}>
          <Formik
            enableReinitialize
            initialValues={initialData}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            validateOnChange={true} // Validate on change to update isValidForm state
            validateOnBlur={false} // Disable onBlur validation to prevent unexpected form state changes
            validate={(values) => {
              // Manually validate the form on change
              validationSchema.validate(values)
                .then(() => setIsValidForm(true))
                .catch(() => setIsValidForm(false));
            }}
          >
            {({ isValid, values, handleChange, handleSubmit, setFieldValue, errors, touched, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <div className="input__wrapper" style={{ width: "100%" }}>
                    <Field
                      type="text"
                      placeholder="Menu Title"
                      className="input__field"
                      name="offer_heading"
                      value={values.offer_heading}
                      onChange={handleChange}
                      style={{ width: "100%" }}
                    />
                    <label htmlFor="offer_heading" className="input__label">Offer Name<span style={{ color: 'red' }}> *</span></label>
                  </div>
                  <ErrorMessage name="offer_heading" component="div" className="error-message" />
                </Form.Group>

                

                <Form.Group className="mb-3">

                    <div className="input__wrapper" style={{  }}>
                      <Multiselect
                        displayValue="name"
                        selectionLimit={1}
                        onKeyPressFn={function noRefCheck() { }}
                        onSearch={function noRefCheck() { }}
                        onSelect={(selectedValues) => {
                          // console.log("Selected values:", selectedValues);
                          setFieldValue("select_offer_duration", selectedValues);
                        }}
                        onRemove={(selectedValues) => {
                          // console.log("Selected values:", selectedValues);
                          setFieldValue("select_offer_duration", selectedValues)
                        }}
                        options={values.offer_duration}
                        selectedValues={values.select_offer_duration}
                        style={{
                          optionContainer:{backgroundColor:'rgb(50,50,50)',color:'white'},
                          inputField: { // To change input field position or margin
                            margin: '10px'
                          },
                          chips: {
                            margin: '5px',
                            background: 'green'
                          }
                        }}
                      />
                      <label htmlFor="item_tag" className="input__label">Offer duration<span style={{ color: 'red' }}> *</span></label>
                    </div>
                    {touched?.select_offer_duration && errors?.select_offer_duration && <div className="error-message">{errors?.select_offer_duration}</div>}
                 </Form.Group>


                 {values.select_offer_duration && values.select_offer_duration[0]?.name === "Custom date range" && (
                 <Form.Group className="mb-3">
                  <DateRangePicker
                  // dateDisplayFormat='dd/MM/yyyy'
                    className='date_picker_style'
                    onChange={range => {
                      // Update Formik field values for trigger_date
                      setFieldValue('trigger_date', range.selection1);
                    }}
                    ranges={[values.trigger_date]}
                  />
                  {errors.trigger_date && touched.trigger_date && <div className="error-message">{errors.trigger_date}</div>}
                </Form.Group>
                 )}

                 <Form.Group className="mb-3">

                    <div className="input__wrapper" style={{}}>
                      <Multiselect
                        displayValue="name"
                        selectionLimit={1}
                        onKeyPressFn={function noRefCheck() { }}
                        onSearch={function noRefCheck() { }}
                        onSelect={(selectedValues) => {
                          // console.log("Selected values:", selectedValues);
                          setFieldValue("select_target_audiance", selectedValues);
                        }}
                        onRemove={(selectedValues) => {
                          // console.log("Selected values:", selectedValues);
                          setFieldValue("select_target_audiance", selectedValues)
                        }}
                        options={values.target_audiance}
                        selectedValues={values.select_target_audiance}
                        style={{
                          inputField: { // To change input field position or margin
                            margin: '10px'
                          },
                          chips: {
                            margin: '5px',
                            background: 'green'
                          }
                        }}
                      />
                      <label htmlFor="target_audiance" className="input__label">Target audience<span style={{ color: 'red' }}> *</span></label>
                    </div>
                    {touched.select_target_audiance && errors.select_target_audiance && <div className="error-message">{errors.select_target_audiance}</div>}
                 </Form.Group>




                <Form.Group className="mb-3">
                  <div className="input__wrapper" style={{ width: "100%" }}>
                    <Field
                      type="text"
                      placeholder="promo_code"
                      className="input__field"
                      name="promo_code"
                      value={values.promo_code}
                      onChange={handleChange}
                      style={{ width: "100%" }}
                    />
                    <label htmlFor="promo_code" className="input__label">Promo code<span style={{ color: 'red' }}> *</span></label>
                  </div>
                  <ErrorMessage name="promo_code" component="div" className="error-message" />
                </Form.Group>

                <Form.Group className="mb-3">
                  <div className="input__wrapper" style={{ width: "100%" }}>
                    <Field
                      type="text"
                      placeholder="promo_code_offer_percent"
                      className="input__field"
                      name="promo_code_offer_percent"
                      value={values.promo_code_offer_percent}
                      onChange={handleChange}
                      style={{ width: "100%" }}
                    />
                    <label htmlFor="promo_code_offer_percent" className="input__label">Offer in percent<span style={{ color: 'red' }}> *</span></label>
                  </div>
                  <ErrorMessage name="promo_code_offer_percent" component="div" className="error-message" />
                </Form.Group>

                <Form.Group className="mb-3" style={{ position: 'relative' }}>
                  <div className="input__wrapper" style={{ width: "100%" }}>
                    <input
                      type="file"
                      className="input__field"
                      name="banner_image"
                      accept="image/*"
                      onChange={(event) => setFieldValue("banner_image", event.currentTarget.files[0])}
                      style={{ width: "100%" }}
                    />
                    <label htmlFor="banner_image" className="input__label">Banner Image</label>
                  </div>
                </Form.Group>

                <Form.Group className="mb-3" style={{ position: 'relative' }}>

                  <img
                    src={values.banner_image_path || 'https://via.placeholder.com/150'}
                    alt="icon"
                    style={{
                      borderRadius: '5%',
                      width: '100%',
                      height: '100%',
                    }}
                    onError={(e) => {
                      e.target.onerror = null; // Prevent infinite loop
                      e.target.src = 'https://img.freepik.com/free-photo/red-gift-tag-price-ticket-with-red-ribbon-isolated-white_1101-2266.jpg?w=1800&t=st=1707896796~exp=1707897396~hmac=d8cb9dbcb33daebdc61816b0f459a94081c770115bccc6f3047aeeac79359c05'; // Set default image source
                    }}
                  />

                </Form.Group>
                <Button
                  type="submit"
                  variant="primary"
                  className="me-md-2 me-0 mb-2 mb-md-0 btn-lg d-flex align-items-center"
                  style={{
                    height: "40px",
                    backgroundColor: !isValidForm ? 'rgba(180, 49, 45, 0.7)' : 'rgb(180,49,45)',
                    fontSize: "12px",
                    border: "none",
                    fontFamily: "Popins",
                  }}
                  disabled={!isValidForm}
                  // Add onClick event handler to close the modal
                >
                  <FaPlus style={{ marginRight: '4px' }} />
                  {
                    onviewclicked ? <span>UPDATE OFFER</span> : <span>CREATE NEW OFFER</span>
                  }
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </main>
  )
}

export default Offers
