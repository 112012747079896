import React, { useEffect } from 'react'
import PageTitle from '../../components/Breadcrumb/PageTitle'
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useState } from 'react';
import { Tab, Tabs, Button, Table, Form } from 'react-bootstrap';
import { BiEdit, BiShow } from 'react-icons/bi';
import './allMenus.css'
import { FaPlus, FaCog, FaMinus } from 'react-icons/fa';
import SearchBar from '../../components/SearchBar/SearchBar';
import { BsSearch } from 'react-icons/bs';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { Modal } from 'react-bootstrap';
import CustomTable from '../../components/Tables/SimpleCustomTable/SimpleCustomTable';
import Pagination from '../../components/Pagination/Pagination';
import { IoIosEye } from 'react-icons/io';
import TableSearchBar from '../../components/SearchBar/TableSearch';
import { API } from '../../api/api';
import { useDispatch, useSelector } from 'react-redux';
import { setDefaulttax, setFooditems, setItemTag, setLocation, setMenu } from '../../redux/actions/dataActions';
import { Formik, Field, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';
import { debounce } from 'lodash';
import Multiselect from 'multiselect-react-dropdown';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { convertToBase64 } from '../../utils/Appconstants';

import { MdDeleteForever } from "react-icons/md";
import { IoIosAddCircleOutline } from "react-icons/io";

// Dummy JSON data
const dummyData = Array.from({ length: 100 }, (_, index) => ({
  id: index + 1,
  item_name: `Item Name ${index + 1}`,
  menu: `Menu ${index + 1}`,
  location: `Tag ${index + 1}`,
  price: `$200${index + 1}`,
}));

const Items = (props) => {

  const [inputFocused, setInputFocused] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const dispatch = useDispatch();
  const fooditemData = useSelector(state => state.data.food_items);
  const menuData = useSelector(state => state.data.menu);
  const locationData = useSelector(state => state.data.location);
  const itemTagData = useSelector(state => state.data.item_tag);
  const defaultTaxData = useSelector(state => state.data.default_tax);

  const [onviewclicked, setOnviewclicked] = useState(false)
  const [initialData, setInitialData] = useState({data: [],
    id: '', select_menu: [], selected_menu_list: [], selected_suggested_menu_list: [], location: [], selected_location_list: [], item_tag: [], select_item_tag: [], default_tax_rate: [], select_default_tax_rate: [], name: '', description: '', amount: '', display_photo: false, poppable: false, online_order_available: false, taxable: false, backgroundImage: null,
    item_image: null
  });
  const [isValidForm, setIsValidForm] = useState(false); // State variable to store isValid value
  const [readOnlyAmount, setReadOnlyAmount] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await GetFooditemsData();
      UpdateData();
      GetItemTagData();
      GetMenuData();
      GetRestaurentData();
      GetDefaultTaxData();
    };

    fetchData();
  }, []);

  const UpdateData = async () => {
    // console.log(menuData, 'menuData===')
    setInitialData(prevState => ({
      ...prevState,
      select_menu: menuData
    }));
    // console.log(initialData['select_menu'], 'select_menu=====')

    // console.log(locationData, 'location===111')
    setInitialData(prevState => ({
      ...prevState,
      location: locationData
    }));
    setInitialData(prevState => ({
      ...prevState,
      item_tag: itemTagData
    }));


    console.log(defaultTaxData, 'default_tax_rate=====11')
    setInitialData(prevState => ({
      ...prevState,
      default_tax_rate: defaultTaxData
    }));
    // console.log(initialData['default_tax_rate'], 'default_tax_rate=====22')
  }

  const GetFooditemsData = async () => {
    console.log('GetFooditemsData===>')
    API.getInstance().base.get("/api/menu-items")
      .then((res) => {
        console.log(res.data.result.data, 'fooditemData====>')
        dispatch(setFooditems(res.data.result.data));
      })
      .catch((error) => {
        // console.log(error, 'fooditemData==error');
      })
      .finally(() => {

      });
  }

  const GetItemTagData = async () => {
    API.getInstance().menu.get("/api/tag")
      .then((res) => {
        dispatch(setItemTag(res.data.result.data));
      })
      .catch((error) => {
      })
      .finally(() => {
      });
  }

  const GetMenuData = async () => {
    // console.log('GetMenuData')
    API.getInstance().menu.get("/api/menu")
      .then((res) => {
        // // console.log(res.data.result.all_menu_data, 'GetMenuData=res111====>')
        dispatch(setMenu(res.data.result.data));
      })
      .catch((error) => {
        // // console.log(error, 'GetMenuData');
      })
      .finally(() => {

      });
  }
  const GetRestaurentData = async () => {
    API.getInstance().menu.get("/api/restaurent")
      .then((res) => {
        dispatch(setLocation(res.data.result.data));
      })
      .catch((error) => {
      })
      .finally(() => {
      });
  }

  const GetDefaultTaxData = async () => {
    API.getInstance().menu.get("/api/default-tax")
      .then((res) => {
        dispatch(setDefaulttax(res.data.result.data));
      })
      .catch((error) => {
      })
      .finally(() => {
      });
  }

  const handleCreateMenu = () => {
    setShowModal(true);
    const menu_item_data = { data: [],id: '', select_menu: [], selected_menu_list: [],selected_suggested_menu_list:[], location: [], selected_location_list: [], item_tag: [], select_item_tag: [], default_tax_rate: [], select_default_tax_rate: [], name: '', description: '', amount: '', display_photo: false, poppable: false, online_order_available: false, taxable: false }

    // console.log(tag_data, 'tag_data======')
    setInitialData(menu_item_data);

    setInitialData(prevState => ({
      ...prevState,
      select_menu: menuData
    }));
    setInitialData(prevState => ({
      ...prevState,
      location: locationData
    }));
    setInitialData(prevState => ({
      ...prevState,
      item_tag: itemTagData
    }));
    setInitialData(prevState => ({
      ...prevState,
      default_tax_rate: defaultTaxData
    }));
  };

  // Function to toggle button enable/disable
  const toggleButton = () => {
    setIsButtonEnabled(!isButtonEnabled);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setOnviewclicked(false);
    setShowModal(false);
  };
  const handleButtonClick1 = () => {
    // Your button 1 click handling logic here
  };
  const handleButtonClick2 = () => {
    // Your button 1 click handling logic here
  };


  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = fooditemData?.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(fooditemData?.length / itemsPerPage);
  const displayPages = Math.min(totalPages, 3); // Ensure we display maximum 3 pages


  // / Display Photo
  const [isDisplayPhoto, setIsDisplayPhoto] = useState(false);

  const handleToggleDisplayPhoto = () => {
    setIsDisplayPhoto(!setIsDisplayPhoto); // Toggle the state
  };

  // Poppable

  const [isPopable, setIsPopable] = useState(false);

  const handleTogglePoppable = () => {
    setIsPopable(!setIsPopable); // Toggle the state
  };

  const [selectedOption, setSelectedOption] = useState('');


  // Search code 
  const [searchValue, setSearchValue] = useState('');

  const handleSearch = (value) => {
    // console.log(value, 'valuessssss')
    setSearchValue(value);
  };

  const [selectedOptionItem, setselectedOptionItem] = useState('');

  // Function to handle dropdown item click
  const handleDropdownItemsClick = (option) => {
    // console.log(option, "option==>");
    setselectedOptionItem(option);
  };

  const [selectedLocationItemsOption, setselectedLocationItemsOption] = useState('');

  // Function to handle dropdown item click
  const handleDropdownItemClickLocationItems = (option) => {
    // console.log(option, "option==>");
    setselectedLocationItemsOption(option);
  };


  const [selectedLocationMenuName, setselectedLocationMenuName] = useState('');

  // Function to handle dropdown item click
  const handleDropdownItemClickMenuName = (option) => {
    // console.log(option, "option==>");
    setselectedLocationMenuName(option);
  };


  const [selectedLocationMenuLocation, setselectedLocationMenuLocation] = useState('');

  // Function to handle dropdown item click
  const handleDropdownItemClickMenuLocation = (option) => {
    // console.log(option, "option==>");
    setselectedLocationMenuLocation(option);
  };


  const [selectedLocationItemsName, setselectedLocationItemsName] = useState('');

  // Function to handle dropdown item click
  const handleDropdownItemClickName = (option) => {
    // console.log(option, "option==>");
    setselectedLocationItemsName(option);
  };

  const handleOnView = async (row) => {
    // console.log(row, 'rowwwww')
    await fetchInitialData(row[0]);
    // console.log(initialData, 'initialData====')
    setOnviewclicked(true)
    setShowModal(true);
  }
  const fetchInitialData = async (id) => {
    try {
      // console.log('fetchInitialData==id', id)
      const response = await API.getInstance().menu.get(`/api/menu-items?id=${id}`); // Adjust the API endpoint as needed
      // console.log(response.data.result.data, 'response.data====>')
      const data = response.data.result.data
      
      const menu_item_data = {data:data?.data || [], id: data.id, select_menu: [], selected_menu_list: data.selected_menu_list,selected_suggested_menu_list:data?.selected_suggested_menu_list || [], location: [], selected_location_list: data.selected_location_list, item_tag: [], select_item_tag: data.select_item_tag, default_tax_rate: [], select_default_tax_rate: data.select_default_tax_rate, name: data.name, description: data.description, amount: data.amount, display_photo: data.display_photo, poppable: data.poppable, online_order_available: data.online_order_available, taxable: data.taxable, item_image: data.item_image }




      console.log(menu_item_data, 'menu_item_data======')
      setInitialData(menu_item_data);

      setInitialData(prevState => ({
        ...prevState,
        select_menu: menuData
      }));
      setInitialData(prevState => ({
        ...prevState,
        location: locationData
      }));
      setInitialData(prevState => ({
        ...prevState,
        item_tag: itemTagData
      }));
      setInitialData(prevState => ({
        ...prevState,
        default_tax_rate: defaultTaxData
      }));


    } catch (error) {
      console.error('Error fetching initial data:', error);
    }
  };


  // Toaster
  const handleSubmit = async (values, { setSubmitting }) => {

    if (values.backgroundImage && values.backgroundImage instanceof Blob) {
      const base64Image = await convertToBase64(values.backgroundImage);
      values.backgroundImage = base64Image;
    } else {
      delete values.backgroundImage;
    }
    console.log(values, 'values====>')

    if (onviewclicked === true) {
      API.getInstance().menu.put(`/api/menu-items/${values.id}`, values)
        .then((res) => {
          GetFooditemsData();
          toast.success('Item updated successfully!');
        })
        .catch((error) => {
          console.error(error);
          toast.error('Failed to update item!');
        });
    } else {
      API.getInstance().menu.post("/api/menu-items", values)
        .then((res) => {
          GetFooditemsData();
          toast.success('Item added successfully!');
        })
        .catch((error) => {
          console.error(error);
          toast.error('Failed to add item!');
        });
    }
  };






  // const validationSchema = Yup.object().shape({
  //   selected_menu_list: Yup.array().min(1, 'Menu is required'),
  //   selected_location_list: Yup.array().min(1, 'Location is required'),
  //   name: Yup.string().required('Name is required'),
  //   description: Yup.string().required('Description is required'),
  //   amount: Yup.string()
  //     .required('Amount is required')
  //     .test('is-number', 'Amount must be a number', value => {
  //       if (value === undefined || value === null) return true; // Allow empty values
  //       return /^-?\d+(\.\d+)?$/.test(value); // Check if the value is an integer or float
  //     }),
  //   select_item_tag: Yup.array().min(1, 'Item Tag is required'),
  //   default_tax_rate: Yup.array().min(1, 'Default Tax Rate is required'),
  // });



  const debouncedHandleChange = debounce((e, handleChange) => {
    handleChange(e);
  }, 300);

  const [isPaymentReceived, setIsPaymentReceived] = useState(false);

  const handleToggle = () => {
    // console.log("asdsdsa");

    setIsPaymentReceived(!isPaymentReceived);
  };

  const OnSelect = (selectedList, selectedItem) => {
    // console.log("event1111",selectedList, selectedItem);

  };




  const validationSchema = Yup.object().shape({
    selected_menu_list: Yup.array().min(1, 'Menu is required'),
    selected_location_list: Yup.array().min(1, 'Location is required'),
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
    // amount: Yup.string()
    //   .required('Amount is required')
    //   .test('is-number', 'Amount must be a number', value => {
    //     if (value === undefined || value === null) return true; // Allow empty values
    //     return /^-?\d+(\.\d+)?$/.test(value); // Check if the value is an integer or float
    //   }),
    select_item_tag: Yup.array().min(1, 'Item Tag is required'),
    select_default_tax_rate: Yup.array().min(1, 'Default Tax Rate is required'),
    // selected_suggested_menu_list: Yup.array().min(1, 'Suggested Menu is required'),
    data: Yup.array().of(
      Yup.object().shape({
        key: Yup.string().required('Key is required'),
        value: Yup.array()
          .min(1, 'Value list must not be empty')
          .of(
            Yup.object().shape({
              name: Yup.string()
                .matches(/^[a-zA-Z0-9\s]+$/, 'Name should not contain special characters')
                .required('Name is required'),
              price: Yup.string()
                .matches(/^\d+(\.\d+)?$/, 'Price must be a valid number')
                .test('is-positive', 'Price must be a positive number', value => parseFloat(value) > 0)
                .required('Price is required')
            })
          )
      }).test({
        name: 'value-list-not-empty',
        exclusive: true,
        message: 'Value list must not be empty',
        test: function(value) {
          return value.value.length > 0;
        }
      })
    )
  });


  return (


    <main id="main" className='main'>
      <Card className="overflow-auto card-custom">
        <Card.Body>
          <PageTitle page="Items" />
          <div className='Tab-parent'>
            <div className='Tab-parent'>
              <div>
                <div className="container">
                  <TableSearchBar placeholder="Search items" onSearch={handleSearch} />
                  <div className="tab-content  rounded-3 mt-4" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                      <CustomTable
                        headers={['#', 'Item Name', 'Menu','Menu Image' ,'Location', 'Price', 'Action']}
                        data={currentItems?.map(data => [data.id, data.name, data.available_menu, data.item_image
                          ,data.available_location, data.amount])}
                        actions={[{ icon: <IoIosEye style={{ color: 'white', fontSize: '20px' }} />, onClick: handleOnView }]}
                      />
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        displayPages={displayPages}
                        goToPage={goToPage}
                        prevPage={prevPage}
                        nextPage={nextPage}
                      />
                    </div>
                  </div>
                </div>

              </div>
              <div className='Tab-right d-flex align-items-center'>
                <Button
                  variant="primary"
                  className="me-md-2 me-0 mb-2 mb-md-0 btn-lg"
                  style={{
                    height: "40px",
                    backgroundColor: 'rgb(180,49,45)',
                    fontSize: "12px",
                    border: "none",
                    fontFamily: "Popins"
                  }}
                  tabIndex={0} // Add tabIndex attribute and set it to 0
                >
                  <span style={{ marginLeft: '5px' }}>Add New Menu</span>
                </Button>


              </div>;
            </div>
            <div className='Tab-right d-flex align-items-center'>
              <Button
                variant="primary"
                className="me-md-2 me-0 mb-2 mb-md-0 btn-lg"
                style={{
                  height: "40px",
                  backgroundColor: 'rgb(180,49,45)',
                  fontSize: "12px",
                  border: "none",
                  fontFamily: "Popins"
                }}
                tabIndex={0}
                onClick={handleCreateMenu} // Open modal on button click
              >
                <span style={{ marginLeft: '5px' }}>Add New Item</span>
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>

      {/* Modal */}
      <Modal show={showModal} onHide={handleCloseModal} centered style={{ Width: '600px' }}>
        <Modal.Header closeButton style={{
          borderBottom: "none",
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "rgb(50, 50, 50)",
          color: "white"
        }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Modal.Title>{onviewclicked ? 'Update Item' : 'New Item'}</Modal.Title>
            <p style={{ borderTop: "none", fontSize: "10px", margin: 0 }}>
              This setting will apply when first added to a menu, though can be,customized for additional location
              and/or menus later.
            </p>
          </div>
        </Modal.Header>

        <Modal.Body style={{ backgroundColor: "var(--card-dark-shade)", Width: '600px' }}>
          <Formik
            enableReinitialize
            initialValues={initialData}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            validateOnChange={true} // Validate on change to update isValidForm state
            validateOnBlur={false} // Disable onBlur validation to prevent unexpected form state changes
            validate={(values) => {
              // Manually validate the form on change
              validationSchema.validate(values)
                .then(() => setIsValidForm(true))
                .catch(() => setIsValidForm(false));
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <div className="mb-3">

                  <div className="input__wrapper" style={{ width: "100%" }}>
                    <Multiselect
                      selectionLimit={1}
                      displayValue="menu_title"
                      onKeyPressFn={function noRefCheck() { }}
                      onSearch={function noRefCheck() { }}
                      onSelect={(selectedValues) => {
                        setFieldValue("selected_menu_list", selectedValues);
                      }}
                      onRemove={(selectedValues) => {
                        setFieldValue("selected_menu_list", selectedValues)
                      }}
                      options={values.select_menu}
                      selectedValues={values.selected_menu_list}
                      style={{
                        inputField: { // To change input field position or margin
                          margin: '10px'
                        },
                        chips: {
                          margin: '5px',
                          background: 'green'
                        }
                      }}
                    />

                    <label htmlFor="select_menu" className="input__label">Menu <span style={{ color: 'red' }}> *</span></label>
                  </div>
                  {touched.selected_menu_list && errors.selected_menu_list && <div className="error-message">{errors.selected_menu_list}</div>}
                </div>

                <div className="mb-3">

                  <div className="input__wrapper" style={{ width: "100%" }}>
                    <Multiselect
                      displayValue="published_name"
                      onKeyPressFn={function noRefCheck() { }}
                      onSearch={function noRefCheck() { }}
                      onSelect={(selectedValues) => {
                        setFieldValue("selected_location_list", selectedValues);
                      }}
                      onRemove={(selectedValues) => {
                        setFieldValue("selected_location_list", selectedValues)
                      }}
                      options={values.location}
                      selectedValues={values.selected_location_list}
                      style={{
                        inputField: { // To change input field position or margin
                          margin: '10px'
                        },
                        chips: {
                          margin: '5px',
                          background: 'green'
                        }
                      }}
                    />
                    <label htmlFor="location" className="input__label">Location <span style={{ color: 'red' }}> *</span></label>
                  </div>
                  {touched.selected_location_list && errors.selected_location_list && <div className="error-message">{errors.selected_location_list}</div>}
                </div>

                <div className="mb-3">
                  <div className="input__wrapper" style={{ width: "100%" }}>
                    <input
                      type="text"
                      name="name"
                      placeholder="Name"
                      className="input__field"
                      value={values.name}
                      onChange={(e) => {
                        handleChange(e);
                        // console.log(e.target.value, 'e.target.value', 'values', values);
                      }}
                      onBlur={handleBlur}
                      style={{ width: "100%" }}
                    />
                    <label htmlFor="name" className="input__label">
                      Name <span style={{ color: 'red' }}> *</span>
                    </label>
                  </div>
                  {touched.name && errors.name && <div className="error-message">{errors.name}</div>}
                </div>
                <div className="mb-3">
                  <div className="input__wrapper" style={{ width: "100%" }}>
                    <input
                      type="text"
                      name="description"
                      placeholder="Description"
                      className="input__field"
                      value={values.description}
                      onChange={(e) => {
                        handleChange(e);
                        // console.log(e.target.value, 'e.target.value', 'values', values);
                      }}
                      onBlur={handleBlur}
                      style={{ width: "100%" }}
                    />
                    <label htmlFor="description" className="input__label">
                      Description <span style={{ color: 'red' }}> *</span>
                    </label>
                  </div>
                  {touched.description && errors.description && <div className="error-message">{errors.description}</div>}
                </div>

                
                <div style={{ display: 'flex' }}>


                  <div className="mb-3">

                    <div className="input__wrapper" style={{ marginRight: '20px' }}>
                      <Multiselect
                        displayValue="name"
                        onKeyPressFn={function noRefCheck() { }}
                        onSearch={function noRefCheck() { }}
                        onSelect={(selectedValues) => {
                          // console.log("Selected values:", selectedValues);
                          setFieldValue("select_item_tag", selectedValues);
                        }}
                        onRemove={(selectedValues) => {
                          // console.log("Selected values:", selectedValues);
                          setFieldValue("select_item_tag", selectedValues)
                        }}
                        options={values.item_tag}
                        selectedValues={values.select_item_tag}
                        style={{
                          inputField: { // To change input field position or margin
                            margin: '10px'
                          },
                          chips: {
                            margin: '5px',
                            background: 'green'
                          }
                        }}
                      />
                      <label htmlFor="item_tag" className="input__label">Item Tag <span style={{ color: 'red' }}> *</span></label>
                    </div>
                    {touched.select_item_tag && errors.select_item_tag && <div className="error-message">{errors.select_item_tag}</div>}
                  </div>

                  <div className="mb-3">
                    <div className="input__wrapper" style={{}}>
                      <Multiselect
                        selectionLimit={1}
                        displayValue="name"
                        onKeyPressFn={function noRefCheck() { }}
                        onSearch={function noRefCheck() { }}
                        onSelect={(selectedValues) => {
                          // console.log("Selected values:", selectedValues);
                          setFieldValue("select_default_tax_rate", selectedValues);
                        }}
                        onRemove={(selectedValues) => {
                          // console.log("Selected values:", selectedValues);
                          setFieldValue("select_default_tax_rate", selectedValues)
                        }}
                        options={values.default_tax_rate}
                        selectedValues={values.select_default_tax_rate}
                        style={{
                          inputField: { // To change input field position or margin
                            margin: '10px',
                            color: 'white'
                          },
                          chips: {
                            margin: '5px',
                            background: 'green'
                          }
                        }}
                      />
                      <label htmlFor="default_tax_rate" className="input__label">Default Tax Rate <span style={{ color: 'red' }}> *</span></label>
                    </div>
                    {touched.default_tax_rate && errors.default_tax_rate && <div className="error-message">{errors.default_tax_rate}</div>}
                  </div>
                </div>

                <Form.Group className="mb-3" style={{ position: 'relative' }}>
                  <div className="input__wrapper" style={{ width: "100%" }}>
                    <input
                      type="file"
                      className="input__field"
                      name="backgroundImage"
                      accept="image/*"
                      onChange={(event) => setFieldValue("backgroundImage", event.currentTarget.files[0])}
                      style={{ width: "100%" }}
                    />
                    <label htmlFor="backgroundImage" className="input__label">Background Image</label>
                  </div>
                  {/* <ErrorMessage name="backgroundImage" component="div" className="error-message" /> */}
                  {/* <Button
                    variant="outline-secondary"
                    style={{
                      border: "none",
                      position: 'absolute',
                      right: '10px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                      outline: 'none'
                    }}
                    onClick={() => console.log('ADD button clicked')}
                  >
                    <i className="bi bi-plus" style={{ color: "white" }}> ADD</i>
                  </Button> */}
                </Form.Group>

                <Form.Group className="mb-3" style={{ position: 'relative' }}>

                  <img
                    src={values.item_image || 'https://via.placeholder.com/150'}
                    alt="icon"
                    style={{
                      borderRadius: '10%',
                      width: '50px',
                      height: '50px',
                    }}
                    onError={(e) => {
                      e.target.onerror = null; // Prevent infinite loop
                      e.target.src = 'https://img.freepik.com/free-photo/red-gift-tag-price-ticket-with-red-ribbon-isolated-white_1101-2266.jpg?w=1800&t=st=1707896796~exp=1707897396~hmac=d8cb9dbcb33daebdc61816b0f459a94081c770115bccc6f3047aeeac79359c05'; // Set default image source
                    }}
                  />
                </Form.Group>



                {/* <div style={{ marginTop: '20px', display: 'flex', gap: '10px', color: `var(--primary-text-color)` }}>
                  <div>Display Photo <span style={{ color: 'red' }}> *</span></div>
                  <input
                    type="checkbox"
                    id="switch"
                    checked={values.display_photo}
                    name="display_photo"
                  />
                  <label1 htmlFor="display_photo" onClick={() => { console.log(!values.display_photo); setFieldValue("display_photo", !values.display_photo); }}></label1>

                </div> */}
                {/* {touched.display_photo && errors.display_photo && <div className="error-message">{errors.display_photo}</div>} */}


                {/* <div style={{ marginTop: '20px', display: 'flex', gap: '10px', color: `var(--primary-text-color)` }}>
                  <div>Poppable <span style={{ color: 'red' }}> *</span></div>
                  <input
                    type="checkbox"
                    id="switch"
                    checked={values.poppable}
                    name="poppable"
                  />
                  <label1 htmlFor="poppable" onClick={() => { console.log(!values.poppable); setFieldValue("poppable", !values.poppable); }}></label1>

                </div> */}
                {/* {touched.poppable && errors.poppable && <div className="error-message">{errors.poppable}</div>} */}


                {/* <div style={{ marginTop: '20px', display: 'flex', gap: '10px', color: `var(--primary-text-color)` }}>
                  <div>Available for Online order <span style={{ color: 'red' }}> *</span></div>
                  <input
                    type="checkbox"
                    id="switch"
                    checked={values.online_order_available}
                    name="online_order_available"
                  />
                  <label1 htmlFor="online_order_available" onClick={() => { console.log(!values.online_order_available); setFieldValue("online_order_available", !values.online_order_available); }}></label1>

                </div> */}
                {/* {touched.online_order_available && errors.online_order_available && <div className="error-message">{errors.online_order_available}</div>} */}

                {/* <div style={{ marginTop: '20px', display: 'flex', gap: '10px', color: `var(--primary-text-color)` }}>
                  <div>Taxable <span style={{ color: 'red' }}> *</span></div>
                  <input
                    type="checkbox"
                    id="switch"
                    checked={values.taxable}
                    name="taxable"
                  />
                  <label1 htmlFor="taxable" onClick={() => { console.log(!values.taxable); setFieldValue("taxable", !values.taxable); }}></label1>

                </div> */}


                <div className="mb-3">

                  <div className="input__wrapper" style={{ width: "100%" }}>
                    <Multiselect
                      selectionLimit={3}
                      displayValue="menu_title"
                      onKeyPressFn={function noRefCheck() { }}
                      onSearch={function noRefCheck() { }}
                      onSelect={(selectedValues) => {
                        setFieldValue("selected_suggested_menu_list", selectedValues);
                      }}
                      onRemove={(selectedValues) => {
                        setFieldValue("selected_suggested_menu_list", selectedValues)
                      }}
                      options={values.select_menu.filter(option =>
                        !values.selected_menu_list.some(selectedItem => selectedItem.id === option.id)
                      )}
                      selectedValues={values.selected_suggested_menu_list}
                      style={{
                        inputField: { // To change input field position or margin
                          margin: '10px'
                        },
                        chips: {
                          margin: '5px',
                          background: 'green'
                        }
                      }}
                    />

                    <label htmlFor="select_menu" className="input__label">Suggest other menu for this item<span style={{ color: 'red' }}> *</span></label>
                  </div>
                  {touched.selected_suggested_menu_list && errors.selected_suggested_menu_list && <div className="error-message">{errors.selected_suggested_menu_list}</div>}
                </div>
                {/* {touched.taxable && errors.taxable && <div className="error-message">{errors.taxable}</div>} */}

                <div className="mb-3">
                  <div className="input__wrapper" style={{ width: "100%" }}>
                    <input
                      type="text"
                      name="amount"
                      placeholder="Amount"
                      className="input__field"
                      value={values.amount}
                      readOnly={readOnlyAmount} 
                      onChange={(e) => {
                        handleChange(e);
                        // console.log(e.target.value, 'e.target.value', 'values', values);
                      }}
                      onBlur={handleBlur}
                      style={{ width: "100%" }}
                    />
                    <label htmlFor="amount" className="input__label">
                      Amount <span style={{ color: 'red' }}> *</span>
                    </label>
                  </div>
                  {touched.amount && errors.amount && <div className="error-message">{errors.amount}</div>}
                </div>

                <Form.Group className="mb-3" style={{ position: "relative" }}>
                  <div style={{ color: `var(--second)` }}>Add Extra</div>

                  <FieldArray name="data">
                    {({ push, remove }) => (
                      <>
                        {values?.data && values?.data.map((heading, headingIndex) => (
                          <div key={headingIndex} style={{ marginTop: '10px' }}>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                              
                              <div style={{ display: 'flex', gap: '10px', color: `var(--primary-text-color)` }}>
                                <Field
                                  type="text"
                                  name={`data.${headingIndex}.key`}
                                  value={heading.key}
                                  className="input__field"
                                  style={heading.key.toLowerCase() === 'size' ? { backgroundColor: 'rgb(255,255,255,.2)' } : {}}

                                />
                              </div>
                              
                              <Button
                                style={{
                                  fontSize: "12px",
                                  border: "none",
                                  backgroundColor: "rgb(180,49,45)",
                                  borderColor: `var(--primary)`,
                                }}
                                type="button"
                                className="btn btn-primary"
                                onClick={() => remove(headingIndex)}
                              >
                                <MdDeleteForever /> Remove Heading
                              </Button>
                              
                            </div>
                            {errors.data && errors.data[headingIndex] && errors.data[headingIndex].key && touched.data && touched.data[headingIndex] && touched.data[headingIndex].key && (
                                <div className="error-message">{errors.data[headingIndex].key}</div>
                              )}
                            <FieldArray name={`data.${headingIndex}.value`}>
                              {({ push: pushItem, remove: removeItem }) => (
                                <>
                                  {heading?.value && heading?.value.map((val, itemIndex) => (
                                    <div key={itemIndex}>
                                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <div className="" style={{ marginRight: "10px" }}>
                                          <div className="input__wrapper">
                                            <Field
                                              type="text"
                                              name={`data.${headingIndex}.value.${itemIndex}.name`}
                                              placeholder="name"
                                              className="input__field"
                                              style={heading.key.toLowerCase() === 'size' && itemIndex == 0 ? { borderColor: '#E5B638'} : {}}

                                            />
                                            <label htmlFor={`data.${headingIndex}.value.${itemIndex}.name`} className="input__label" style={{color:heading.key.toLowerCase() === 'size' && itemIndex == 0 ?'#E5B638':null}}>
                                              name <span style={{ color: "red" }}> *</span>
                                            </label>
                                          </div>
                                          <ErrorMessage
                                          name={`data.${headingIndex}.value.${itemIndex}.name`}
                                          component="div"
                                          className="error-message"
                                        />
                                        </div>
                                        <div className="" style={{ marginRight: "10px" }}>
                                          <div className="input__wrapper">
                                            <Field
                                              type="text"
                                              name={`data.${headingIndex}.value.${itemIndex}.price`}
                                              placeholder="price"
                                              className="input__field"
                                              style={heading.key.toLowerCase() === 'size' && itemIndex == 0 ? { borderColor: '#E5B638' } : {}}
                                              onChange={(e) => {
                                                if (heading.key.toLowerCase() === 'size' && itemIndex == 0) {
                                                  // Set the value of "amount" if the key is "size"
                                                  setFieldValue(`amount`, e.target.value);
                                                  // setReadOnlyAmount(true);      
                                                  console.log(itemIndex,'itemIndex=>>11232')   
                                                  setFieldValue(`data.${headingIndex}.value.${itemIndex}.selected`, true);               
                                                }
                                                // Use setFieldValue to update the value of the field
                                                setFieldValue(`data.${headingIndex}.value.${itemIndex}.price`, e.target.value); 
                                                
                                              }}
                                            />
                                            <label htmlFor={`data.${headingIndex}.value.${itemIndex}.price`} className="input__label" style={{color:heading.key.toLowerCase() === 'size' && itemIndex == 0 ?'#E5B638':null}}>
                                              {heading.key.toLowerCase() === 'size' && itemIndex == 0 ? "Default selected price":"price"} <span style={{ color: "red" }}> *</span>
                                            </label>
                                          </div>
                                          <ErrorMessage
                                              name={`data.${headingIndex}.value.${itemIndex}.price`}
                                              component="div"
                                              className="error-message"
                                            />
                                        </div>


                                        <Button
                                          style={{
                                            fontSize: "12px",
                                            border: "none",
                                            backgroundColor: "rgb(180,49,45)",
                                            borderColor: `var(--primary)`,
                                          }}
                                          type="button"
                                          className="btn btn-primary"
                                          onClick={() => removeItem(itemIndex)}
                                        >
                                          <MdDeleteForever />
                                        </Button>
                                      </div>
                                    </div>
                                  ))}
                                  <Button
                                    style={{
                                      fontSize: "12px",
                                      border: "none",
                                      backgroundColor: "green",
                                      borderColor: `var(--primary)`,
                                      marginTop: '10px'
                                    }}
                                    className="btn btn-primary"
                                    onClick={() => pushItem({ name: "", price: "", selected: false })}
                                  >
                                    <IoIosAddCircleOutline /> Add Item
                                  </Button>
                                </>
                              )}
                            </FieldArray>
                            {headingIndex !== values.data.length - 1 && // Check if this is not the last item
                              <div style={{ border: "1px dashed rgb(123,123,123)", marginTop: '20px', marginBottom: '20px' }}></div>
                            }
                          </div>
                        ))}
                      </>
                    )}
                  </FieldArray>

                  <div style={{ border: "1px dashed rgba(229, 182, 56, .7)", marginTop: '20px' }}></div>
                  <div
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      gap: "10px",
                      color: `var(--primary-text-color)`,
                    }}
                  >
                    <Button
                      style={{
                        height: "40px",
                        fontSize: "12px",
                        border: "none",
                        backgroundColor: "green",
                        borderColor: `var(--primary)`,
                      }}
                      className="btn btn-primary"
                      onClick={() => {
                        const updatedData = [...values.data];
                        updatedData.push({ key: "", value: [] });
                        setFieldValue("data", updatedData);
                      }}
                    >
                      Add Heading
                    </Button>
                  </div>
                  {/* <p>{JSON.stringify(values.data)}</p> */}
                </Form.Group>

                <Button
                  style={{
                    marginTop: '20px',
                    height: "40px",
                    fontSize: "12px",
                    border: "none",
                    backgroundColor: !isValidForm ? 'rgba(180, 49, 45, 0.7)' : 'rgb(180,49,45)',
                    borderColor: `var(--primary)`,
                  }}
                  disabled={!isValidForm}
                  type="submit"
                  className="btn btn-primary"
                  onClick={() => setShowModal(false)} // Add onClick event handler to close the modal
                >
                  <FaPlus style={{ marginRight: '4px' }} />
                  {
                    onviewclicked ? <span>UPDATE ITEM</span> : <span>ADD ITEM</span>
                  }
                </Button>
              </Form>
            )}
          </Formik>

        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button> 
           Add save button or any other action button
        </Modal.Footer> */}
      </Modal>
    </main>
  )
}

export default Items
