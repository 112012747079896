import React from 'react'

function BillingPayment() {
  return (
    <div>
      
    </div>
  )
}

export default BillingPayment
