import React from 'react'
import './pageTitle.css'
import Nav from '../NavBar/Nav'
import SearchBar from '../SearchBar/SearchBar'
import { useNavigate } from 'react-router-dom';
function PageTitle({ page }) {
  const navigate = useNavigate();
  const handleHome = () => {
    navigate('/dashboard');
};
  return (
    <div className="pagetitle">
      <div className='heading'>
        <h1>{page}</h1>
        <Nav />
      </div>
      <div className='menu-nav' >
        <a onClick={handleHome}>
          <i className="bi bi-house-door breadcrumb-icon"></i>
        </a>
        <i className="bi bi-slash breadcrumb-icon">{page}</i>
      </div>
    </div>
  )
}

export default PageTitle
