import React from "react";
import { Helmet } from "react-helmet";
import { APP_LANGUAGE, APP_NAME, HOME } from "./Appconstants";

const SEO = ({
  title,
  description,
  content,
  icon57,
  icon72,
  ogDescription,
  ogImage,
  children,
}) => {
  return (
    <>
      <Helmet
        encodeSpecialCharacters
        titleTemplate={`${'Hungers Point Admin'} - %s`}
        defaultTitle={'HOME'}
      >
        {/* html attributes */}
        <html lang={'APP_LANGUAGE'} />
        {/* body attribute */}
        <body />
        <meta charSet="utf-8" />
        {/* title attributes and value */}
        <title itemProp="name" lang="en">
          {title}
        </title>
        {/* multiple meta elements */}
        <meta name="description" content={description} />
        {/* Open Graph Type */}
        {/* <meta property="og:type" content={content} />
        <meta property="og:description" content={ogDescription} data-rh="true" />
        <meta property="og:image" content={ogImage} data-rh="true" /> */}

      <meta property="og:url"                content="http://www.nytimes.com/2015/02/19/arts/international/when-great-minds-dont-think-alike.html" />
      <meta property="og:type"               content="article" />
      <meta property="og:title"              content="When Great Minds Don’t Think Alike" />
      <meta property="og:description"        content="How much does culture influence creative thinking?" />
      <meta property="og:image"              content="https://upload.wikimedia.org/wikipedia/commons/thumb/1/11/Test-Logo.svg/1200px-Test-Logo.svg.png" />


        {/* Twitter */}
        <meta name="twitter:card" content={ogImage} />
        {/* multiple link elements */}
        <link rel="canonical" href="http://mysite.com/example" />
        <link rel="apple-touch-icon" href={icon57} />
        <link rel="apple-touch-icon" sizes="72x72" href={icon72} />
      </Helmet>
      {children}
    </>
  );
};

export default SEO;
