import React, { useEffect } from 'react'
import './main.css'
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { BiCalendar } from 'react-icons/bi'
import PageTitle from '../../components/Breadcrumb/PageTitle';
import { useDispatch } from 'react-redux';
import { setAdminUserdata, setFooditems, setItemTag, setMenu, setOrders } from '../../redux/actions/dataActions';
import { API } from '../../api/api';
import dash_icon from  '../../images/dashboard_graph.png';
import SEO from "../../utils/SEO";
 


function Main() {
  const dispatch = useDispatch();
  useEffect(() => {
    GetMenuData()
    GetFooditemsData()
    GetItemsTagData()
    GetOrderData()
  }, []);
  const GetMenuData = async () => {
    console.log('GetMenuData')
    API.getInstance().menu.get("/api/menu")
      .then((res) => {
        dispatch(setMenu(res.data.result.data));
      })
      .catch((error) => {
      })
      .finally(() => {
      });
  }

  const GetFooditemsData = async () => {
    console.log('GetFooditemsData===>')
    API.getInstance().base.get("/api/menu-items")
      .then((res) => {
        console.log(res.data.result.data, 'fooditemData====>')
        dispatch(setFooditems(res.data.result.data));
      })
      .catch((error) => {
      })
      .finally(() => {
      });
  }

  const GetItemsTagData = async () => {
    console.log("GetItemsTagData====22222");
    try {
      API.getInstance().menu.get("/api/tag")
      .then((res) => {
        const data = res.data.result.data;
        console.log(data, 'itemtagData====API');
        dispatch(setItemTag(data));
      })
      .catch((error) => {
        // console.log(error, 'GetMenuData');
      })
      .finally(() => {
      });
    } catch (error) {
      console.log(error, 'itemtagData==error');
    }
  };

  const GetOrderData = async () => {
    API.getInstance().base.get("/api/orders")
      .then((res) => {
        dispatch(setOrders(res.data.result.data));
      })
      .catch((error) => {
        console.log(error, 'orderData==error');
      })
      .finally(() => {
       
      });
  }

  const page = "Dashboard";
  return (
    <main id="main" className='main'>
      <SEO
      title={'Dashboard'}
      description={'Char Dham yatra is the most sacred yatra for Hindu in India. there are four temples included in Char Dham Yatra, Yamunotri, Gangotri, Kedarnath & Badrinath. All of these four are sacred places for Hindus. Every year many people come to take blessings from these four temples. Aventuras brings to you 11 nights Char Dham tour package from Delhi NCR in which you can visit all Char Dham of Uttarakhand. This package starts from Delhi as per your convenience at the time of booking. '}
      content={'SEO_CONTENT[0].content'}
      icon57={'https://upload.wikimedia.org/wikipedia/commons/thumb/1/11/Test-Logo.svg/1200px-Test-Logo.svg.png'}
      icon72={'https://upload.wikimedia.org/wikipedia/commons/thumb/1/11/Test-Logo.svg/1200px-Test-Logo.svg.png'}
      ogDescription={'Char Dham yatra is the most sacred yatra for Hindu in India. there are four temples included in Char Dham Yatra, Yamunotri, Gangotri, Kedarnath & Badrinath. All of these four are sacred places for Hindus. Every year many people come to take blessings from these four temples. Aventuras brings to you 11 nights Char Dham tour package from Delhi NCR in which you can visit all Char Dham of Uttarakhand. This package starts from Delhi as per your convenience at the time of booking. '}
      ogImage={'https://upload.wikimedia.org/wikipedia/commons/thumb/1/11/Test-Logo.svg/1200px-Test-Logo.svg.png'}
    >

    </SEO>
      <Card className="overflow-auto card-custom ">

        <Card.Body>

          <PageTitle page={page} />

          {/* 2 small cards */}
          <Row xs={1} md={4} className="g-4">
            <Col >
              <Card className='Card1'>
                <Card.Body>

                </Card.Body>
              </Card>
            </Col>

            <Col>
              <Card className='Card1'>
                <Card.Body>
                </Card.Body>
              </Card>
            </Col>
            <Col>

            </Col>
          </Row>
          <br />
          {/* <br /> */}
          <div className='dash-sub-heading'>Website Overview</div>
          {/* 3 cards */}
          <Row className="g-3">
            <Col md={4}>
              <Card className='Card'>
                <div className='card-super-parent'>
                  <div className='card-parent'>
                    <div className='card-icon' >
                     <img src={dash_icon} style={{ width:'80%' }} />
                    </div>
                    <div className='card-right' >
                      <div className='car-right-txt'>Total Site Vistits</div>
                      <div className='card-right-number'>722</div>
                    </div>
                  </div>
                  <div className='card-parent'>
                    <div className='card-icon' style={{backgroundColor:'#DEC1A7'}}>
                     <img src={dash_icon} style={{ width:'80%' }} />

                    </div>
                    <div className='card-right'>
                      <div className='car-right-txt'>Dish Likes</div>
                      <div className='card-right-number'>3</div>
                    </div>
                  </div>
                </div>

              </Card>
            </Col>
            <Col md={4}>
              <Card className='Card'>
                <div className='card-super-parent'>
                  <div className='card-parent'>
                    <div className='card-icon' style={{backgroundColor:'#FF7878'}}>
                     <img src={dash_icon} style={{ width:'80%' }} />
                    </div>
                    <div className='card-right'>
                      <div className='car-right-txt'>Total Order Placed</div>
                      <div className='card-right-number'>500</div>
                    </div>
                  </div>
                  <div className='card-parent'>
                    <div className='card-icon' style={{backgroundColor:'#F0F3DE'}} >
                     <img src={dash_icon} style={{ width:'80%' }} />

                    </div>
                    <div className='card-right'>
                      <div className='car-right-txt'>New Review</div>
                      <div className='card-right-number'>2</div>
                    </div>
                  </div>
                </div>

              </Card>
            </Col>
            <Col md={4}>
              <Card className='Card'>
                <div className='card-super-parent'>
                  <div className='card-parent'>
                    <div className='card-icon' style={{backgroundColor:'#F3DEE6'}}>
                      <img src={dash_icon} style={{ width:'80%' }} />
                    </div>
                    <div className='card-right'>
                      <div className='car-right-txt'>Revenue(this year)</div>
                      <div className='card-right-number'>$12000</div>
                    </div>
                  </div>
                  <div className='card-parent'>
                    <div className='card-icon' style={{backgroundColor:'#C6DEA7'}}>
                     <img src={dash_icon} style={{ width:'80%' }} />

                    </div>
                    <div className='card-right'>
                      <div className='car-right-txt'>Online Order Revenue</div>
                      <div className='card-right-number'>$1,203.05</div>
                    </div>
                  </div>
                </div>

              </Card>
            </Col>
          </Row>
          <br />

          {/* 4 cards */}
          <Row xs={1} md={2} className="g-4">
            {Array.from({ length: 3 }).map((_, idx) => (
              <Col key={idx}>
                <Card className='Card1'>
                  <div className='card-parent-revenue'>
                    <div className='heading'>Online Ordering Revenue</div>
                    <div className='number'>$ 4,1244.00</div>
                    <div className='response'><i className="bi bi-arrow-up-right"></i>  Up 32% over the past 7 days</div>
                  </div>
                </Card>
              </Col>
            ))}
            <Col>
              <Card className='Card1'>
                <div className='card-parent-revenue'>
                  <div className='heading'>Online Order Placed</div>
                  <div className='number'>25</div>
                  <div className='response'><i className="bi bi-arrow-up-right"></i>  Up 32% over the past 10 days</div>
                </div>
              </Card>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </main>

  )
}

export default Main
